import { NavigationState, RootState } from "@/interfaces/state";
import { Navigation } from "@/interfaces/models";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { Api } from "@/api/config";

const api = new Api({
  hostUrl: process.env.HOST + "/api",
  token: ""
});

const getDefaultState = () => {
  return {
    all: JSON.parse(localStorage.getItem("nav") ?? "[]"),
    currentNavigation: null
  };
};

const state = getDefaultState();

const getters: GetterTree<NavigationState, RootState> = {
  navigations: (state: NavigationState): Navigation[] => state.all,
  currentNavigation: (state: NavigationState): Navigation | undefined =>
    state.currentNavigation
};

const actions: ActionTree<NavigationState, RootState> = {
  load: async ({ commit, dispatch }: any): Promise<boolean> => {
    let succesRate = 0;
    try {
      const navigation = await api.get("navigation/render/1?type=RFR");
      if (navigation.status == 200) {
        succesRate++;
        localStorage.setItem("nav", JSON.stringify(navigation.data));
        dispatch("setCurrentNavigation", navigation.data);
      }
    } catch (error) {
      console.log(error);
    }

    return succesRate == 1;
  },
  setCurrentNavigation: (
    { commit }: any,
    newNavigation: Navigation
  ): Promise<boolean> =>
    new Promise((resolve, reject) => {
      commit("setCurrentNavigation", newNavigation);
      resolve(true);
    })
};

const mutations: MutationTree<NavigationState> = {
  setRecords: (state: NavigationState, payload: any[]): Navigation[] =>
    (state.all = payload),

  setCurrentNavigation: (
    state: NavigationState,
    payload: Navigation
  ): Navigation => (state.currentNavigation = payload)
};

const namespaced = true;

export const navigation: Module<any, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
