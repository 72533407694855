










import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { WebComponents } from "@/components";
import _ from "lodash-es";

const pages = namespace("pages");

@Component({ components: { ...WebComponents } })
export default class Page extends Vue {
  @pages.Getter currentPage?: any;
  knownComponents = Object.values(WebComponents);

  compName(name: string): string {
    const slices = name.split(".");
    return slices[1];
  }

  compKey(component: any): string {
    return `page-${this.currentPage.id}-comp-${_.kebabCase(
      this.compName(component.__component)
    )}-${component.id}`;
  }

  compNameExists(c: any): boolean {
    const name = this.compName(c.__component);
    return this.knownComponents.some(x => _.kebabCase(x.name) === name);
  }
}
