import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VueEasyLightbox from "vue-easy-lightbox";

Vue.config.productionTip = false;

Vue.use(VueEasyLightbox);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data: () => {
    return { host: process.env.HOST };
  }
}).$mount("#app");
