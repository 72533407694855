import Vue from "vue";
import Vuex, { StoreOptions, Store } from "vuex";
import { RootState } from "@/interfaces/state";
import { navigation } from "@/store/modules/navigation";
import { pages } from "@/store/modules/pages";
import { authentication } from "@/store/modules/authentication";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "1.0.0",
    uniqueID: undefined,
    loaded: false,
    authStatus: true
  },
  getters: {
    authStatus: (state: RootState): boolean => state.authStatus
  },
  mutations: {
    setAuthStatus: (state: RootState, payload: boolean): any => {
      state.authStatus = payload;
    }
  },
  actions: {
    loadAll: async ({ dispatch, commit }: any): Promise<boolean> => {
      const navStatus: boolean = await dispatch("navigation/load");
      const pageStatus: boolean = await dispatch("pages/load");
      commit("setAuthStatus", navStatus && pageStatus ? true : false);
      return navStatus && pageStatus ? true : false;
    }
  },
  modules: { navigation, pages, authentication }
};

export default new Store<RootState>(store);
