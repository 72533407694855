import { AuthenticationState, RootState } from "@/interfaces/state";
import { User } from "@/interfaces/models";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import AxiosInstanceBase from "@/api/config";

const getDefaultState = () => {
  return {
    jwt: localStorage.getItem("JWT") ?? undefined,
    user: undefined
  };
};

const state = getDefaultState();

const getters: GetterTree<AuthenticationState, RootState> = {
  jwt: (state: AuthenticationState): string | undefined =>
    state?.jwt?.length > 0 ? state?.jwt?.replace("", "") : undefined,
  user: (state: AuthenticationState): User | undefined => {
    return state?.user?.username ? { ...state.user } : undefined;
  }
};

const actions: ActionTree<AuthenticationState, RootState> = {
  login: async (
    { commit, dispatch },
    loginRequest: { identifier: string; password: string }
  ): Promise<boolean> => {
    const { data, status } = await AxiosInstanceBase.post("auth/local", {
      identifier: loginRequest.identifier,
      password: loginRequest.password
    });

    if (status == 200) {
      AxiosInstanceBase.updateConfig({
        hostUrl: process.env.HOST,
        token: data.jwt
      });
      commit("setState", data as AuthenticationState);
      return true;
    } else {
      console.log("Something aint right");
      return false;
    }
  }
};

const mutations: MutationTree<AuthenticationState> = {
  setState: (state: AuthenticationState, payload: AuthenticationState): any => {
    state.jwt = payload.jwt;
    state.user = payload.user;
  }
};

const namespaced = true;

export const authentication: Module<any, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
