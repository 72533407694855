







































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, Action, Mutation, namespace } from "vuex-class";
import { Navigation, User } from "@/interfaces/models";
import { addDynamicRoutes } from "@/router/util";

const pages = namespace("pages");
const navigation = namespace("navigation");
const auth = namespace("authentication");

@Component
export default class App extends Vue {
  @auth.Getter user?: User;
  @Getter authStatus?: boolean;
  @Mutation setAuthStatus: any;
  @Action loadAll: any;
  @pages.Action updateCurrentPage: any;
  @pages.Getter currentPage: any;
  @navigation.Getter navigations?: Navigation[];
  @navigation.Getter currentNavigation: any;
  drawer = false;
  mobileMode = false;
  subnavActive = false;

  // @Watch("user") onUserChange(user: User | undefined): void {
  //   if (user?.username) {
  //     this.setAuthStatus(true);
  //     this.loadAll();
  //   } else {
  //     this.$router.push({ name: "Login" });
  //   }
  // }

  // @Watch("authStatus") onAuthStatusChange(status: boolean): void {
  //   if (status === false) {
  //     this.$router.push({ name: "Login" });
  //   } else {
  //     this.$router.push({ name: "Home" });
  //   }
  // }

  @Watch("$route") onRouteChange(route: any): void {
    if (route.matched.length > 0) {
      this.updateCurrentPage(route.name);
    } else {
      if (this.$router.currentRoute.path !== "/404") {
        this.$router.push({ name: "404" });
      }
    }
  }

  @Watch("currentNavigation") onNavigationChange(): void {
    if (this.currentNavigation) {
      addDynamicRoutes(this, this.currentNavigation.pages);
    }
  }

  @Watch("screenWidth") onWidthChange(width: number): void {
    this.mobileMode = width <= 992;
  }

  async created(): Promise<void> {
    const handleResize = () => (this.mobileMode = window.innerWidth <= 992);
    window.addEventListener("resize", handleResize);
    handleResize();
    const status = await this.loadAll();
    if (status) {
      this.updateCurrentPage(this.$route.name);
      // this.$router.push(this.$router.currentRoute.path);
    }
  }

  navItemClickHandler(e: Event, route: string): void {
    if (this.mobileMode) {
      const el = e.currentTarget;
      const wrapperEl = (el as HTMLElement)?.nextSibling ?? false;
      if (wrapperEl && (wrapperEl as HTMLElement).classList) {
        const isOpen = (wrapperEl as HTMLElement).classList.contains("open");
        const iconClick =
          (e.target as HTMLElement).tagName ===
          ((el as HTMLElement).querySelector(".nav-main-icon i") as HTMLElement)
            .tagName;

        if (isOpen && !iconClick) {
          this.$router.push(route);
          this.toggleMenu();
        }

        if (!isOpen) {
          (wrapperEl as HTMLElement).classList.add("open");
          ((el as HTMLElement).querySelector(
            ".nav-main-icon"
          ) as HTMLElement).classList.add("open");
        }

        if (isOpen && iconClick) {
          (wrapperEl as HTMLElement).classList.remove("open");
          ((el as HTMLElement).querySelector(
            ".nav-main-icon"
          ) as HTMLElement).classList.remove("open");
        }
      } else {
        this.$router.push(route);
        this.toggleMenu();
      }
    } else {
      this.$router.push(route);
      this.toggleMenu();
    }
  }

  toggleMenu(): void {
    const menu = document.querySelector(".nav-main");
    if (menu?.classList.contains("nav-main--open")) {
      menu.classList.remove("nav-main--open");
    } else {
      menu?.classList.add("nav-main--open");
    }
  }
}
