import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Page from "../views/Page.vue";
import PageNotFound from "../views/404.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Page
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue")
  },
  {
    path: "/about/:id",
    name: "About",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

// const rawRoutes = localStorage.getItem("routes");
// let dynRoutes: RouteConfig[] | undefined;
// let parsedRoutes: any | undefined = undefined;
// if (typeof rawRoutes === "string") {
//   parsedRoutes = JSON.parse(rawRoutes);
// }
// if (parsedRoutes) {
//   const navItems: any[] = parsedRoutes.items;
//   navItems.map((item: any) => {
//     if (item.items.length > 0) {
//       item.items.map((x: any) =>
//         navItems.push({ ...x, path: `${item.path}/${x.path}` })
//       );
//     }
//   });
//   dynRoutes = navItems.map((item: any) => {
//     return { name: item.title, path: item.path, component: Page };
//   });
//   Array.prototype.push.apply(routes, dynRoutes as RouteConfig[]);
// }

const router = new VueRouter({
  routes
});

export default router;
