// Chunked Component collections
export const WebComponents = {
  HelloWorld: () =>
    import(/* webpackChunkName: "hello-world" */ "@/components/HelloWorld.vue"),
  Hero: () => import(/* webpackChunkName: "hero" */ "@/components/Hero.vue"),
  ShowcaseLarge: () =>
    import(
      /* webpackChunkName: "showcase-large" */ "@/components/ShowcaseLarge.vue"
    ),
  TextContent: () =>
    import(
      /* webpackChunkName: "text-content" */ "@/components/TextContent.vue"
    ),
  Tile: () => import(/* webpackChunkName: "tile" */ "@/components/Tile.vue"),
  MultiTile: () =>
    import(/* webpackChunkName: "multi-tile" */ "@/components/MultiTile.vue"),
  Gallery: () =>
    import(/* webpackChunkName: "gallery" */ "@/components/Gallery.vue"),
  PhotoCollectionGallery: () =>
    import(
      /* webpackChunkName: "photo-collection-gallery" */ "@/components/PhotoCollectionGallery.vue"
    ),
  PhotoCollectionNav: () =>
    import(
      /* webpackChunkName: "photo-collection-nav" */ "@/components/PhotoCollectionNav.vue"
    )
};
