import Axios, { AxiosInstance } from "axios";
import { set } from "vue/types/umd";

export interface ApiConfig {
  hostUrl: string;
  token?: string;
}

export class Api {
  private axios: AxiosInstance;

  constructor(config: ApiConfig) {
    this.axios = Axios.create({
      baseURL: config.hostUrl
    });

    this.updateConfig(config);
  }

  public get interceptors() {
    return this.axios.interceptors;
  }

  public set autorization(token: string) {
    this.axios.defaults.headers.Authorization = `Bearer ${token}`;
  }

  public get instance() {
    return this.axios;
  }

  public deleteAutorization() {
    delete this.axios.defaults.headers.Authorization;
  }

  public get(url: string): Promise<any> {
    return this.axios.get(url);
  }

  public post(url: string, data: any): Promise<any> {
    return this.axios.post(url, data);
  }

  public put(url: string, data: any): Promise<any> {
    return this.axios.put(url, data);
  }

  public delete(url: string): Promise<any> {
    return this.axios.delete(url);
  }

  public updateConfig(config: ApiConfig) {
    if (config.token && config.token.length > 0) {
      localStorage.setItem("JWT", config.token);
      this.axios.defaults.headers.Authorization = `Bearer ${config.token}`;
    }

    this.axios.defaults.baseURL = config.hostUrl;
  }
}

function setupApi(): Api {
  return new Api({
    hostUrl: process.env.HOST + "/api",
    token: localStorage.getItem("JWT") ?? ""
  });
}

const api = setupApi();

export default api;
