import { PageState, RootState } from "@/interfaces/state";
import { Page } from "@/interfaces/models";
import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import AxiosInstanceBase from "@/api/config";

const getDefaultState = () => {
  return {
    all: JSON.parse(localStorage.getItem("pages") ?? "[]"),
    currentPage: null
  };
};

const state = getDefaultState();

const getters: GetterTree<PageState, RootState> = {
  pages: (state: PageState): Page[] => state.all,
  currentPage: (state: PageState): Page | undefined => state.currentPage
};

const actions: ActionTree<PageState, RootState> = {
  load: async ({ commit, dispatch, rootGetters }: any): Promise<boolean> => {
    let succesRate = 0;
    try {
      const items = await AxiosInstanceBase.get("pages?populate=deep");
      if (items.status == 200) {
        succesRate++;
        localStorage.setItem("pages", JSON.stringify(items.data.data));
        commit("setRecords", items.data.data);
      }
    } catch (error) {
      console.log(error);
    }

    return succesRate == 1;
  },
  setCurrentPage: ({ commit }: any, newPage: Page): Promise<boolean> =>
    new Promise((resolve, reject) => {
      commit("setCurrentPage", newPage);
      resolve(true);
    }),
  updateCurrentPage: async (
    { getters, rootGetters, dispatch },
    pageName: string
  ): Promise<boolean> => {
    const { id }: Page = getters.pages.find(
      (page: Page) =>
        page.attributes.name.toUpperCase() === pageName.toUpperCase()
    );
    if (id) {
      const page = await AxiosInstanceBase.get(`pages/${id}?populate=deep`);
      if (page.status == 200) {
        dispatch("setCurrentPage", page.data.data);
      }
      return true;
    }
    return false;
  }
};

const mutations: MutationTree<PageState> = {
  setRecords: (state: PageState, payload: any[]): Page[] =>
    (state.all = payload),

  setCurrentPage: (state: PageState, payload: Page): Page =>
    (state.currentPage = payload)
};

const namespaced = true;

export const pages: Module<any, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
