import { RouteConfig } from "vue-router";
import Page from "@/views/Page.vue";

export function addDynamicRoutes(state: any, pages: any) {
  const pagesArray = Object.values(pages);
  const dynRoutes: RouteConfig[] | undefined = pagesArray.map((item: any) => {
    return {
      name: item.title,
      path: item.path,
      component: Page
    };
  });

  state.$router.addRoutes(dynRoutes);
}
